<template>
  <div>
    <!--begin::balance-->
    <b-card no-body class="mb-5">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row">
              <div class="form-group col-md-4 mb-2 mt-2">
                <label>{{ $t('customer_balance_report.customer') }}</label>
                <multiselect v-model="customer"
                             :placeholder="$t('customer_balance_report.customer')"
                             label="name" track-by="id"
                             :options="customers"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getCustomers($event)">
                </multiselect>
              </div>
              <div class="form-group col-md-4 mb-2 mt-2">
                <label for="from_date">{{ $t('from_date') }}</label>
                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
              </div>

              <div class="form-group col-md-4 mb-2 mt-2">
                <label for="to_date">{{ $t('to_date') }}</label>
                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
              </div>


              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
    <div class="card card-custom mb-5">
      <div class="card-body">
        <div class="row mb-10">
          <div class="col-12 mt-10" id="summaryTableTitle">
            <h4 class="text-center">{{ $t('MENU.simple_customer_balance_report') }}</h4>
            <h5 class="text-center">
              <span v-if="date_time_now">{{ $t('time') }} {{ date_time_now }}</span>
            </h5>
            <h5 class="text-center">
              <span v-if="filters.from_date">{{ $t('from') }} {{ filters.from_date }} </span>
              <span v-if="filters.to_date"> {{ $t('to') }} {{ filters.to_date }}</span>
            </h5>
          </div>
          <div class="d-flex col-12 justify-content-end p-4">
            <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
              <download-excel
                  class="dropdown-item"
                  :fetch="getDataList"
                  :fields="json_fields"
                  :name="$t('MENU.simple_customer_balance_report')+'.xls'">
                <i class="la la-file-excel"></i>{{ $t('excel') }}
              </download-excel>
              <button class="dropdown-item" @click="printData('summaryTable', 'print')">
                <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
              </button>
              <button class="dropdown-item" @click="printData('summaryTable', 'pdf')">
                <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
              </button>
            </b-dropdown>

          </div>
        </div>
        <div class="balancesTable col-12 mb-4 mt-10" id="balancesTable">
          <div style="display: flex; justify-content: space-around;text-align: center;background-color: #f8f9fa;border-radius: 5px;align-items: center;height: 85px;">
            <div>
              <h4>الرصيد الافتتاحي</h4>
              <h4>{{balances.opening_balance ? balances.opening_balance : '0'}}</h4>
            </div>
            <div>
              <h4>إجمالي الفواتير</h4>
              <h4>{{balances.total_sales ? balances.total_sales : '0'}}</h4>
            </div>
            <div>
              <h4>المبلغ المدفوع</h4>
              <h4>{{balances.total_payments ? balances.total_payments : '0'}}</h4>
            </div>
            <div>
              <h4>المبلغ المستحق</h4>
              <h4>{{balances.debit ? balances.debit : '0'}}</h4>
            </div>
          </div>
        </div>
        <div class="col-12 mt-10">
          <div class="table-responsive">
            <table class="table" :id="'summaryTable'" :class="'summaryTable'">
              <thead>
              <tr style="background-color: #f8f9fa;">
                <th>{{ $t('simple_customer_balance_report.date') }}</th>
                <th>{{ $t('simple_customer_balance_report.type') }}</th>
                <th>{{ $t('simple_customer_balance_report.type_id') }}</th>
<!--                <th>{{ $t('simple_customer_balance_report.reference') }}</th>-->
                <th>{{ $t('simple_customer_balance_report.debit_after') }}</th>
                <th>{{ $t('simple_customer_balance_report.credit_after') }}</th>
                <th>{{ $t('simple_customer_balance_report.amount') }}</th>
                <th>{{ $t('simple_customer_balance_report.currency') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, index) in data" :key="'c'+getRandom()+index">
                <td>{{ row.reference_date }}</td>
                <td>{{ row.type }}</td>
                <td>{{ row.model_code }}</td>
<!--                <td>{{ row.reference_date }}</td>-->
                <td>{{ row.debit_after }}</td>
                <td>{{ row.credit_after }}</td>
                <td>{{ row.amount }}</td>
                <td>{{ row.currency_name }}</td>
              </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>{{toDate}}</td>
                  <td colspan="4">{{ $t('simple_customer_balance_report.net_movement') }}</td>
                  <td>{{ totals.total }}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>{{toDate}}</td>
                  <td colspan="4">{{ $t('simple_customer_balance_report.closing_balance') }}</td>
                  <td>{{ totals.final_balance }}</td>
                  <td></td>
                </tr>
              </tfoot>
            </table>

          </div>
        </div>
        <div class="col-12 text-center" v-if="page">
          <button class="btn btn-warning" @click="loadMore">
            <p class="mb-0">
              <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
              {{ $t('load_more') }}
            </p>
          </button>
        </div>
      </div>
    </div>
    <div class="header-export" id="headerExport">
      <div class="col-12 p-0" id="headerPage">
        <ReportHeader
            :from-date.sync="filters.from_date"
            :to-date.sync="toDate"
            :customer.sync="customer_data"
            :company.sync="company_data"
            :title="$t('simple_customer_balance_report.account_statement')"
        ></ReportHeader>

        <!--        <h4 v-if="company_data" class="text-center">{{ company_data.business_name }}</h4>-->
        <!--        <h4 class="text-center">{{ $t('MENU.simple_customer_balance_report') }}</h4>-->
        <!--        <h5 class="text-center">-->
        <!--          <span v-if="date_time_now">{{ $t('time') }} {{ date_time_now }}</span>-->
        <!--        </h5>-->
        <!--        <h5 class="text-center">-->
        <!--          <span v-if="filters.from_date">{{ $t('from') }} {{ filters.from_date }} </span>-->
        <!--          <span v-if="filters.to_date"> {{ $t('to') }} {{ filters.to_date }}</span>-->
        <!--        </h5>-->
        <!--        <div v-if="customer_data" style="text-align: right;direction: rtl;">-->
        <!--          <p>{{ $t('simple_customer_balance_report.customer') }} : {{ customer_data.code ? customer_data.code : '' }} - {{ customer_data.name ? customer_data.name : '' }}</p>-->
        <!--          <p>{{ $t('simple_customer_balance_report.address') }} : {{ customer_data.address ? customer_data.address : '' }}</p>-->
        <!--          <p>{{ $t('simple_customer_balance_report.mobile') }} : <span style="text-align: left;direction: ltr;display: inline-block;">{{ customer_data.phone ? customer_data.phone : '' }}</span></p>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="selector-export" id="selectorExport">

    </div>
    <!--end::balance-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
import ReportHeader from "@/view/content/printing-heads/reports/Main.vue";
import jsPdfExport from "@/core/config/jsPdfExport";

export default {
  name: "index-client-balances",
  components: {ReportHeader},
  data() {
    return {
      mainRoute: '/reports/customer-simple-statement-report',

      date_time_now: null,
      dataList: [],
      dataTotal: [],
      data: [],
      customer_data: {},
      company_data: {},
      customers: [],
      status_list: [],
      users: [],
      procedures: [],
      balances: {},
      totals: {},

      filters: {
        customer_id: this.$route.query.customer_id ? this.$route.query.customer_id : null,
        from_date: this.$route.query.from_date ? this.$route.query.from_date : null,
        to_date: this.$route.query.to_date ? this.$route.query.to_date : null,
        status: null,
        hide_zero: false,
        show_details: false,
      },
      customer: null,
      employee: null,
      page: 0,
      current_date: null,

    }
  },
  watch: {
    customer: function (val) {
      if (val) {
        this.filters.customer_id = val.id;
      } else {
        this.filters.customer_id = null;
      }
    },
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('simple_customer_balance_report.date')] = 'reference_date';
      fields[this.$t('simple_customer_balance_report.type')] = 'type';
      fields[this.$t('simple_customer_balance_report.type_id')] = 'model_code';
      // fields[this.$t('simple_customer_balance_report.reference')] = 'code';
      fields[this.$t('simple_customer_balance_report.debit_after')] = 'debit_after';
      fields[this.$t('simple_customer_balance_report.credit_after')] = 'credit_after';
      fields[this.$t('simple_customer_balance_report.amount')] = 'amount';
      fields[this.$t('simple_customer_balance_report.currency')] = 'currency_name';

      return fields;
    },

    toDate: function (){
      return (this.filters.to_date ? this.filters.to_date :this.current_date)
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.clients_reports"), route: '/clients/reports'}, {title: this.$t("MENU.simple_customer_balance_report")}]);

    this.date_time_now = this.$moment().format('DD-MM-YYYY H:mm');
    this.current_date = this.$moment().format('DD-MM-YYYY');

    this.getStatus();

    if (this.$route.query.customer_id){
      this.customer = {
        id: this.$route.query.customer_id,
        name: this.$route.query.customer_name,
      }
      this.doFilter();
    }

  },
  methods: {
    doFilter() {
      this.page = 1;
      this.getData();
    },
    resetFilter() {
      this.filters.customer_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.status = null;
      this.filters.hide_zero = false;
      this.filters.show_details = false;
      this.customer = null;
      this.employee = null;

      this.getData();
    },

    dataReport() {
      this.setReportLog('excel', 'customer balance report');
      return this.data;
    },
    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getData();
    },
    getData() {
      let _filter = {...this.filters};
      // _filter.hide_zero = _filter.hide_zero ? 1 : 0;
      // _filter.show_details = _filter.show_details ? 1 : 0;
      if (_filter.customer_id){
        ApiService.get(this.mainRoute, {params: {..._filter, page: (this.page ? this.page : 1)}}).then((response) => {
          this.data = response.data.data.data;
          this.company_data = response.data.data.details.company;
          this.customer_data = response.data.data.details.customer;
          this.balances = response.data.data.balances;
          this.totals = response.data.data.total;
        });
      }else{
        this.$errorAlertMessage(this.$t('please_select_customer'));
      }

    },

    getCustomers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get("base/dependency/customers", {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`base/dependency/users`, {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
      } else {
        this.users = [];
      }
    },
    getStatus() {
      ApiService.get("base/dependency/natural_account").then((response) => {
        this.status_list = response.data.data;
      });
    },

    getDataList() {
      this.setReportLog('excel', 'customer balance report');
      // let _filter = this.filter;
      // _filter.hide_zero = _filter.hide_zero ? 1 : 0;
      // _filter.show_details = _filter.show_details ? 1 : 0;
      // return ApiService.get(this.mainRouteExport,{params:{..._filter}}).then(response => {
      //     return response.data.data;
      // }).catch(e => {
      //     console.log(e);
      // });
      let _data = this.data;
      // _data.push({
      //     code: this.$t('total'),
      //     account_no: '',
      //     name: '',
      //     manual_status: '',
      //     employee: '',
      //     balance_before: '',
      //     total_sales: '',
      //     total_refund: '',
      //     net_sales: this.dataTotal.sum_net_sales,
      //     total_payments: this.dataTotal.sum_total_payments,
      //     settlements: this.dataTotal.sum_settlements,
      //     balance: this.dataTotal.sum_balance,
      //     phone: '',
      //     address: '',
      //     category: '',
      // });
      return _data;
    },
    getRandom() {
      return Math.floor(Math.random() * 10000);
    },

    printData(tableId, type) {
      this.setReportLog('pdf', 'customer balance report');
      let _container = document.getElementById('selectorExport');
      _container.innerHTML = '';


      let headerPage = document.getElementById('headerPage').cloneNode(true);
      _container.appendChild(headerPage);


      let balancesTable = document.getElementById('balancesTable').cloneNode(true);
      _container.appendChild(balancesTable);

      let divToPrint = document.getElementById(tableId).cloneNode(true);
      _container.appendChild(divToPrint);


      if (type == 'print'){
        let newWin = window.open("");
        newWin.document.write("<link href='https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap' rel='stylesheet'><link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'><style>#selectorExport{direction: rtl;text-align: right;margin: 10px;font-family: 'Cairo'}</style>" + _container.outerHTML);
        setTimeout(() => {
          newWin.print();
          // newWin.close();
        }, 100)
      }else{
        setTimeout(()=>{
          exportPDFInnerAction('selectorExport', type, this.$t('MENU.simple_customer_balance_report'), this.$t('MENU.simple_customer_balance_report'), 'selectorExport', true);
        }, 500);

      }

    },

    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
};
</script>
<style>
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.selector-export {
  opacity: 0 !important;
  z-index: -1000;
  width: 0.0001px;
  height: 0.0001px;
  overflow: auto;
  direction: rtl;
  float: right;
  text-align: right;
  display: block;
}

.header-export {
  opacity: 0 !important;
  z-index: -1000;
  width: 0.0001px;
  height: 0.0001px;
  overflow: auto;
  direction: rtl;
  float: right;
  text-align: right;
  display: block;
}
</style>